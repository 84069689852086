import Head from "next/head";
import Script from "next/script";
const Meta = (props) => (
  <Head>
    <title>{props.title || "Classy Closets"}</title>
    <meta charSet="UTF-8" />
    <meta name="google-site-verification" content="7jLw51OmKOuP0MvIAQoCjmNhMWPDue5WmED3bCTMK9Q" />
    <meta name="p:domain_verify" content="a12b5e25ff60d6781cafa414f43c8f22" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <meta name="description" content={props.desc} />
    <meta name="robots" content={props.robots} />

    <meta property="og:type" content="website" />
    <meta name="og:title" property="og:title" content={props.title || "Classy Closets"} />
    <meta
      name="og:description"
      property="og:description"
      content={props.desc}
    />
    <meta property="og:image" content={props.image || "/images/logo/ClassyClosets-logo.png"} />
    <meta property="og:site_name" content="Classy Closets" />
    <meta property="og:url" content={`${props.canonical}`} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:label1" content="Written by" />
    <meta name="twitter:data1" content="Classy Closets" />
    <meta name="twitter:label2" content="Est. reading time" />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.desc} />
    <meta name="twitter:site" content="@classyclosets" />
    <meta name="twitter:creator" content="@classyclosets" />

    <link rel="icon" type="image/x-icon" href="/static/images/favicon.ico" />

    {props.css && <link rel="stylesheet" href={`${props.css}`} />}
    {props.image && <meta name="classyclosets:image" content={`${props.image}`} />}
    {props.canonical && <link rel="canonical" href={`${props.canonical}`} />}
    {props.js && <Script type="text/javascript" src={`${props.js}`}></Script>}
  </Head>
);
export default Meta;
