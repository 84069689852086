import Meta from "./index";
import metaData from "../../data/_metaData";

const MetaCommon = ({ metaDataKey, customMetaData }) => {
  const pageMetaData = !metaDataKey
    ? customMetaData
    : metaData.hasOwnProperty(metaDataKey)
    ? metaData[metaDataKey]
    : metaData["home"];

  return (
    <Meta
      title={pageMetaData?.title}
      desc={pageMetaData?.description}
      canonical={pageMetaData?.canonical}
      robots={pageMetaData?.robots}
    />
  );
};

export default MetaCommon;
